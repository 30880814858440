export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'HomeIcon'
      }
    ]
  }
]
